import { AppProps } from 'next/app';
import Head from 'next/head';
import './_app.scss';
import { appWithTranslation } from 'next-i18next';
import nextI18nNextConfig from '../next-i18next.config';
import { useApollo } from '../graphql/apollo-client';
import { ApolloProvider } from '@apollo/client';

function App({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps);

  return (
    <ApolloProvider client={apolloClient}>
      <Head>
        <title>Welcome to inspring-web!</title>
      </Head>

      <Component {...pageProps} />
    </ApolloProvider>
  );
}

export default appWithTranslation(App, nextI18nNextConfig);
