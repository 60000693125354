var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d912714a6ca1ce1afb9cce486e8f5d51c3d46edc"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import {
  dsn,
  replaysOnErrorSampleRate,
  replaysSessionSampleRate,
  sentryEnabled,
  sentryEnvironment,
  tracesSampler,
} from './utils/sentry';

Sentry.init({
  dsn,
  // Replay may only be enabled for the client-side
  integrations: [Sentry.replayIntegration()],
  environment: sentryEnvironment,
  enabled: sentryEnabled,
  tracesSampler,
  replaysSessionSampleRate,
  replaysOnErrorSampleRate,
});
