import { SamplingContext } from '@sentry/types';

export const dsn = 'https://f1910bc175f90f3e32f76621b6aef4ef@o97239.ingest.sentry.io/4506815913656320';

export const sentryEnvironment =
  process.env.NEXT_SENTRY_ENVIRONMENT || process.env.SENTRY_ENVIRONMENT || process.env.NODE_ENV || 'development';

export const sentryEnabled = sentryEnvironment !== 'e2e';

export const tracesSampleRate = sentryEnvironment === 'production' ? 0.1 : 0;

export const replaysSessionSampleRate = sentryEnvironment === 'production' ? 0.1 : 0;

export const replaysOnErrorSampleRate = sentryEnvironment === 'production' ? 1 : 0;

export const tracesSampler = (ctx: SamplingContext): false | number => {
  const ignoredPages = ['/healthz'];

  const shouldIgnore = ctx.transactionContext && ignoredPages.includes(ctx.transactionContext?.name);
  if (shouldIgnore) {
    return false;
  }

  return tracesSampleRate;
};
