const { resolve } = require('path');

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  defaultNamespace: 'common',
  i18n: {
    defaultLocale: 'en-US',
    locales: ['en-US'],
  },
  localePath: resolve('./public/locales'),
  reloadOnPrerender: false,
};
